<template>
  <div class="fami-container batchadd-container">
    <div class="top">
      <p class="top-lable">
        <span class="require-prefix">*</span>文件:
      </p>
      <el-upload
        class="upload-add"
        :on-remove="handleRemove"
        :limit="1"
        :action="actionUrl"
        :data="uploadData"
        :headers="headers"
        name="agent_file"
        :auto-upload="false"
        :file-list="fileList"
        :on-success="successHandle"
        :on-error="errorHandle"
        :before-upload="beforeUpload"
        ref="upload"
      >
        <button class="green-border-btn select-file-btn">
          选择文件
        </button>
      </el-upload>
      <el-button
        type="text"
        @click="downTemp"
        class="down-temp"
      >
        下载模版格式
      </el-button>
    </div>
    <div class="option-wrap">
      <p class="tips">
        当前支持csv和xls格式文件（大小在10M以内），若导入的二级代理已经存在，该代理不会导入.
      </p>
      <el-button
        type="text"
        @click="logVisible = true"
        class="open-log"
        v-if="errorRow.length>=1"
      >
        打开上传日志
      </el-button>
      <div class="button-group">
        <button
          class="green-border-btn"
          @click="goback"
        >
          取消
        </button>
        <button
          class="green-btn"
          @click="submitUpload"
        >
          保存
        </button>
      </div>
    </div>
    <el-dialog
      title="上传日志"
      v-model="logVisible"
      width="80%"
      style="max-width:400px"
      @close="dialogClose"
    >
      <div class="logList">
        <p
          class="item"
          v-for="item in errorRow"
          :key="item.row"
        >
          <span class="title">{{ `第${item.row - 1}列` }}</span>
          <span class="desc">{{ item.result }}</span>
        </p>
      </div>
    </el-dialog>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { exportJson2Excel } from '@/utils/excel'
import { getToken } from '@/utils/cookies'
import { getSignData } from '@/utils/https'
import { IndexMessage } from '@/model/orderModel'
import { useRouter } from 'vue-router'
interface ImportItem{
  result: string
  row: number
  status: number
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const upload = ref(null)
    const uploadObj = reactive({
      headers: { 'Monimaster-Token': getToken() },
      uploadData: getSignData(),
      actionUrl: process.env.VUE_APP_BASE_API + '/agent/second/fileImport'
    })
    const data = reactive({
      path: process.env.BASE_URL,
      fileList: [],
      hasSuccess: false,
      downTemp: () => {
        // 导出模板
        const tHeader = ['姓名', '手机号（需要使用真实手机号）', '密码（英文+数字且不少于8位）']
        exportJson2Excel(tHeader as string[], [], '二级代理模板')
      },
      logVisible: false,
      errorRow: [],
      beforeUpload: (file: IndexMessage<string | number>) => {
        const isFile = /.xls|.csv|.xlsx/.test(file.name as string)
        const isLt10M = Number(file.size) / 1024 / 1024 < 10
        if (!isFile) {
          ElMessage.error('只能上传csv和xls格式文件！')
        }
        if (!isLt10M) {
          ElMessage.error('文件大小不能超过 10MB！')
        }
        return isFile && isLt10M
      },
      submitUpload: () => {
        (upload.value as any).submit()
      },
      successHandle: (response: any) => {
        if (response.code === 200) {
          ElMessage.success('保存成功')
          data.errorRow = []
          router.go(-1)
        } else if (response.code === 207) { // 内容格式有错误
          data.errorRow = response.data
          data.logVisible = true
          response.data.forEach((el: ImportItem) => {
            if (el.status === 1) {
              data.hasSuccess = true
              return false
            }
          })
        } else {
          ElMessage.error(response.msg as string)
        }
        (upload.value as any).clearFiles()
      },
      errorHandle: (err: any) => {
        console.log(err)
        ElMessage.error(String(err))
      },
      goback: () => {
        router.go(-1)
      },
      dialogClose: () => {
        // 有已经成功的返回
        if (data.hasSuccess) {
          data.goback()
        }
      }
    })
    return { ...toRefs(data), upload, ...toRefs(uploadObj) }
  }
})
</script>

<style lang="scss" scoped>
.top{
  position: relative;
  display: flex;
  align-items: flex-start;
  .top-lable{
    height: 35px;
    line-height: 35px;
  }
  .down-temp{
    position: absolute;
    left: 200px;
  }
}
.upload-add{
  max-width: 400px;
  margin:0 20px;
}
.batchadd-container{
  ::v-deep(.el-dialog){
    max-width: 400px;
  }
}

.logList{
  max-height: calc(70vh - 84px);
  overflow-y: scroll;
  .item{
    margin-bottom: 5px;
    color: #999;
    .title{
      color: #51aa8d;
      width: 50px;
      display: inline-block;
    }
  }
}
.require-prefix{
  color:rgba(81, 170, 141, 1)
}
.select-file-btn{
  border-radius: 5px;
}
.download-template{
  color: rgba(53, 125, 255, 1);
}
.tips{
  color: rgba(144, 147, 153, 1);
  line-height: 1.4;
}
.option-wrap{
  margin-top: 20px;
  padding-left: 4em;
}
.button-group{
  padding-bottom: 30px;
  display: flex;
  justify-content: flex-start;
  max-width: 500px;
  margin-top: 60px;
  button+button{
    margin-left: 30px;
  }
}
.green-border-btn,.green-btn{
  padding: 0 1.4em;
}
@media screen and (max-width:414px) {
  .download-template{
    display: block;
    margin-top: 10px;
  }
  .option-wrap{
    padding: 0;
  }
}
</style>
