
import { defineComponent, reactive, toRefs, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { exportJson2Excel } from '@/utils/excel'
import { getToken } from '@/utils/cookies'
import { getSignData } from '@/utils/https'
import { IndexMessage } from '@/model/orderModel'
import { useRouter } from 'vue-router'
interface ImportItem{
  result: string
  row: number
  status: number
}
export default defineComponent({
  setup() {
    const router = useRouter()
    const upload = ref(null)
    const uploadObj = reactive({
      headers: { 'Monimaster-Token': getToken() },
      uploadData: getSignData(),
      actionUrl: process.env.VUE_APP_BASE_API + '/agent/second/fileImport'
    })
    const data = reactive({
      path: process.env.BASE_URL,
      fileList: [],
      hasSuccess: false,
      downTemp: () => {
        // 导出模板
        const tHeader = ['姓名', '手机号（需要使用真实手机号）', '密码（英文+数字且不少于8位）']
        exportJson2Excel(tHeader as string[], [], '二级代理模板')
      },
      logVisible: false,
      errorRow: [],
      beforeUpload: (file: IndexMessage<string | number>) => {
        const isFile = /.xls|.csv|.xlsx/.test(file.name as string)
        const isLt10M = Number(file.size) / 1024 / 1024 < 10
        if (!isFile) {
          ElMessage.error('只能上传csv和xls格式文件！')
        }
        if (!isLt10M) {
          ElMessage.error('文件大小不能超过 10MB！')
        }
        return isFile && isLt10M
      },
      submitUpload: () => {
        (upload.value as any).submit()
      },
      successHandle: (response: any) => {
        if (response.code === 200) {
          ElMessage.success('保存成功')
          data.errorRow = []
          router.go(-1)
        } else if (response.code === 207) { // 内容格式有错误
          data.errorRow = response.data
          data.logVisible = true
          response.data.forEach((el: ImportItem) => {
            if (el.status === 1) {
              data.hasSuccess = true
              return false
            }
          })
        } else {
          ElMessage.error(response.msg as string)
        }
        (upload.value as any).clearFiles()
      },
      errorHandle: (err: any) => {
        console.log(err)
        ElMessage.error(String(err))
      },
      goback: () => {
        router.go(-1)
      },
      dialogClose: () => {
        // 有已经成功的返回
        if (data.hasSuccess) {
          data.goback()
        }
      }
    })
    return { ...toRefs(data), upload, ...toRefs(uploadObj) }
  }
})
